header {
  height: 100vh;
  overflow: hidden;
  padding-bottom: 5rem;
}

.header__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'left right';
  position: relative;
  height: 100%;
}

.header__left-column,
.header__right-column {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.header__left-column {
  grid-area: left;
  align-items: flex-start;
}
.header__right-column {
  grid-area: right;
  align-items: flex-end;
}

.header__left-column h5 {
  font-size: 1.33rem;
}

.header__left-column p {
  margin: 1.5rem 0 1.5rem;
  padding-right: 3rem;
}

.cta {
  margin-top: 1rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  align-items: center;
}

.flag {
  margin-left: 0.5rem;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 0;
}

.header__me {
  background: var(--color-primary);
  aspect-ratio: 1/1;
  width: 90%;
  border-radius: 50rem 0rem 50rem 50rem;
  overflow: hidden;
  padding: 2rem 4rem 0rem 4rem;
}

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 2rem;
  font-weight: 300;
  font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
  .header__container {
    grid-template-columns: 1fr;
    grid-template-areas: 'right' 'left';
  }

  .header__right-column {
    align-items: center;
    justify-content: center;
  }

  .header__left-column {
    justify-content: flex-start;
  }

  .header__me {
    border-radius: 50rem;
    width: 60%;
    padding: 1rem 2.3rem 0rem 2.3rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .header__container {
    grid-template-columns: 1fr;
    grid-template-areas: 'right' 'left';
  }

  .header__right-column {
    align-items: center;
    justify-content: center;
  }

  .header__left-column {
    justify-content: flex-start;
  }

  .header__left-column h5 {
    font-size: 1rem;
  }

  .header__left-column p {
    font-size: 14px;
    margin: 1rem 0 1rem;
    padding-right: 0;
  }

  .header__me {
    border-radius: 50rem;
    width: 65%;
    padding: 1.2rem 2.2rem 0rem 1.8rem;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }

  .cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
