.language-selector {
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  width: 120px;
}

.language-selector.icon {
  position: unset;
  width: unset;
  background: transparent;
  display: flex;
  color: var(--color-light);
  margin-left: 1.3rem;

}

.selected-language {
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid var(--color-primary-variant);
  background-color: var(--color-primary);
  color: var(--color-bg);
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-selector.icon > .selected-language {
  padding: 0.9rem;
  border: unset;
}
.language-selector.icon > .selected-language > svg {
  font-size: 1.1rem;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 1rem;
  transition: var(--transition);
}

.arrow.open {
  transform: rotate(-135deg);
}

.language-options {
  width: inherit;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 0.4rem;
  border: 2px solid var(--color-primary);
  background-color: var(--color-bg);
  position: absolute;
  right: 0;
  top: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-1rem) scaleY(0);
  transform-origin: top;
  transition: var(--transition);
}

.language-selector.open .language-options {
  opacity: 1;
  visibility: visible;
  transform: translateY(0) scaleY(1);
  transition: var(--transition);
}

.language-selector.icon > .language-options {
  top: -150%;
  right: -1%;
  transform-origin: bottom;
}

.language-options li {
  padding: 0.75rem 1.6rem;
  cursor: pointer;
}

.language-options li:hover {
  background-color: var(--color-bg-variant);
}

.language-options li.active {
  background: var(--color-primary);
  color: black;
}
@media screen and (max-width: 600px) {
  .language-selector {
    top: 2rem;
    right: 0;
    width: 90px;
  }

  .language-selector.icon {
    margin-left: 1rem;
  }

  .language-selector.icon > .language-options {
    top: -110%;
    right: 3%;
  }

  .selected-language {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .language-options li {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .arrow {
    border-width: 0 2px 2px 0;
    margin-left: 0.7rem;
    margin-bottom: 3px;
  }
}
