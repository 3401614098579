.accordion__details {
  display: flex;
  gap: 1rem;
  padding: 8px 50px 16px;
  border-bottom: 1px solid var(--color-light);
  background-color: var(--color-bg);
  overflow: hidden;
  transition: margin 400ms ease;
  border-radius: 1rem;
}

.accordion__details.expanded {
  margin: 16px 0px;
}
.accordion__details.expanded:first-of-type {
  margin-top: 0px;
}
.accordion__details.expanded:last-of-type {
  margin-bottom: 0px;
}

.accordion__details h4 {
  display: flex;
  user-select: none;
  cursor: pointer;
  margin: 12px 0;
  gap: 1rem;
}

.accordion__details small {
  display: block;
  font-size: 15px;
}

.accordion__details-icon {
  width: 15px;
  margin-top: 6px;
  color: var(--color-primary);
}

.accordion__arrow {
  color: var(--color-primary);
  display: inline-block;
}

.accordion__arrow-wrapper {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.scale {
  animation: scale 1.5s 1.5s ease-in-out;
}

@media screen and (max-width: 600px) {
  .accordion__details small {
    font-size: unset;
  }
}
