.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12rem;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__options a {
  color: var(--color-white);
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
}

.contact__options a:hover .contact__option-btn {
  color: var(--color-white);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option-btn {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  color: var(--color-primary);
}

form {
  display: flex;
  flex-direction: column;
}

form > :disabled {
  opacity: 0.4;
  border-color: var(--color-light);
}

.contact__button-container {
  margin-top: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
  margin-top: 1.2rem;
  margin-bottom: 0.3rem;
}

.first-field {
  margin-top: 0;
}

.error-border {
  border-color: var(--color-error) !important;
}

.error-message {
  color: var(--color-error);
  font-size: 12px;
}

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
