@keyframes loader {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}
.loader {
  width: 70px;
  height: 41px;
  animation: 1s linear 0s infinite running loader;
}
