.popup__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.popup__content {
  background-color: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  text-align: center;
}

.popup__content h2 {
  margin-bottom: 0.6rem;
}

.popup__button-group {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  margin-top: 2rem;
}

@media screen and (max-width: 1024px) {
  .popup__content {
    width: var(--container-width-md);
    padding: 2rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .popup__content {
    width: var(--container-width-sm);
    padding: 2rem 1rem;
  }
}
